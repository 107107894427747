import { INQUIRY_TYPES } from "../constants";
import { initBank } from "./common.inits";

export const initPromoterApprovalInquiry = () => ({
    _terms: [],
    type: INQUIRY_TYPES.PROMOTER_APPROVAL.value,
    ci: null,
    phone: null,
    bank: initBank(),
    residentRegistrationNumber: null,
});
