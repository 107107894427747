import { BONUS_TYPES } from "../constants";
import { initBank } from "./common.inits";

export const initBonus__debit = (bonus = {}) => ({
    _certification: bonus?._certification || null,

    type: bonus?.type || BONUS_TYPES.BONUS__DEBIT.value,
    amount: bonus?.amount || 0,
    remain: bonus?.remain || 0,
    meta: {
        ...(bonus?.meta || {}),
        bank: initBank(bonus?.meta?.bank),
        charge: bonus?.meta?.charge || 0,
    },
});
