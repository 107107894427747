import Vue from "vue";
import dayjs from "dayjs";

/**
 * @param {Vue} Vue
 * @param {*} options
 */
const install = function (Vue, options) {
    Vue.prototype.$dayjs = dayjs;
    Vue.prototype.$dayjs.locale("ko");
};

Vue.use({ install });

export default dayjs;
