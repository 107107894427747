import { USER_TYPES } from "../constants";

export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    email: user?.email || null,
    phone: user?.phone || null,

    referrer: user?.referrer || null,
    promoterCode: user?.promoterCode || null,

    type: user?.type ?? USER_TYPES.PERSON.value,

    bank: {
        ...(user?.bank || {}),
        code: user?.bank?.code || null,
        accountHolder: user?.bank?.accountHolder || null,
        accountNumber: user?.bank?.accountNumber || null,
    },

    // 기업회원
    businessRegistration: user?.businessRegistration || null,
    _businessRegistration: user?._businessRegistration || null,
});
