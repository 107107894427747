<template>
    <div class="header-search-bar">
        <v-img width="24" src="/images/icon/icon-search.svg" class="header-search-bar__icon" />
        <v-text-field v-model="searchValue" outlined dense hide-details placeholder="원하는 상품을 찾아보세요." @keydown.enter="search"></v-text-field>
    </div>
</template>

<script>
export default {
    data: () => ({
        searchValue: "",
    }),
    methods: {
        search() {
            this.$router.push({ path: "/shop/sales", query: { searchValue: this.searchValue } });
        },
    },
};
</script>

<style lang="scss" scoped>
.header-search-bar {
    position: relative;
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        z-index: 1;
    }
    .v-text-field.v-input {
        ::v-deep {
            .v-input__control {
                .v-input__slot {
                    padding-left: 40px;
                    background-color: var(--v-grey-lighten5);
                }
            }
            input {
                color: var(--v-grey-base);
            }
        }
    }
}
</style>