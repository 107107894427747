import { initGeolocation } from "./common.inits";

export const initOrderSender = (sender = {}) => ({
    ...sender,

    username: sender?.username ?? null,

    name: sender?.name ?? null,
    phone: sender?.phone ?? null,
    email: sender?.email ?? null,

    postcode: sender?.postcode ?? null,
    address1: sender?.address1 ?? null,
    address2: sender?.address2 ?? null,
});

export const initOrderReceiver = (receiver = {}) => ({
    ...receiver,

    title: receiver?.title ?? null,

    name: receiver?.name ?? null,
    phone: receiver?.phone ?? null,
    email: receiver?.email ?? null,

    postcode: receiver?.postcode ?? null,
    address1: receiver?.address1 ?? null,
    address2: receiver?.address2 ?? null,

    geolocation: initGeolocation(receiver?.geolocation),

    isDefault: receiver?.isDefault ?? false,
});

export const initOrderAccount = (account = {}) => ({
    ...account,

    accountNumber: account?.accountNumber ?? null,
    accountHolder: account?.accountHolder ?? null,
    accountBankCode: account?.accountBankCode ?? null,
});

export const initOrderCashReceipt = (cashReceipt = {}) => ({
    ...cashReceipt,

    enabled: cashReceipt?.enabled ?? false,
    phone: cashReceipt?.phone ?? null,
});

export const initOrder = (order = {}) => ({
    ...order,

    _carts: [...(order?._carts ?? [])],
    carts: [...(order?.carts ?? [])],

    _coupons: [...(order?._coupons ?? [])],
    coupons: [...(order?.coupons ?? [])],

    point: order?.point ?? 0,

    sender: initOrderSender(order?.sender),
    receiver: initOrderReceiver(order?.receiver),

    account: initOrderAccount(order?.account),
    cashReceipt: initOrderCashReceipt(order?.cashReceipt),
    paymentMethod: order?.paymentMethod ?? "card",
    requestMessage: order?.requestMessage ?? null,
});
