var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer footer-navigation"
  }, [_c('div', {
    staticClass: "footer-top-button"
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": "",
      "elevation": "4",
      "color": "white"
    },
    on: {
      "click": _vm.scrollToTop
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-up.svg"
    }
  })], 1)], 1), _c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', [_vm._l(_vm.categories, function (category) {
    return [_c('v-col', {
      key: category.code,
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px"
    }, [_vm._v(" " + _vm._s(category.name) + " ")]), _c('v-row', {
      staticClass: "row--sm"
    }, [_vm._l(category.children, function (child) {
      return [_c('v-col', {
        key: child.code,
        attrs: {
          "cols": "12"
        }
      }, [_c('a', {
        staticClass: "txt txt--sm txt--dark font-weight-light",
        attrs: {
          "href": `/shop?category=${child.code}`
        }
      }, [_vm._v(" " + _vm._s(child.name) + " ")])])];
    })], 2)], 1)];
  }), _vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item.text,
      attrs: {
        "cols": "12",
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('v-row', {
      staticClass: "row--sm"
    }, [_vm._l(item.children, function (child) {
      return [_c('v-col', {
        key: child.text,
        attrs: {
          "cols": "12"
        }
      }, [_c('router-link', {
        staticClass: "txt txt--sm txt--dark font-weight-light",
        attrs: {
          "to": child.value
        }
      }, [_vm._v(" " + _vm._s(child.text) + " ")])], 1)];
    })], 2)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }