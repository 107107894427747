import { SHOP_POINT_UNITS } from "../constants";
import { initUsage } from "./common.inits";
import { initIssue } from "./common.inits";

export const initShopPoint = (point = {}) => ({
    ...point,
    name: point.name ?? null,
    unit: point.unit ?? SHOP_POINT_UNITS.AMOUNT.value,
    value: point.value ?? 0,
    limit: point.limit ?? 0,
    count: point.count ?? 1,

    issue: initIssue(point.issue),
    usage: initUsage(point.usage),
});
