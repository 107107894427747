var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('h2', {
    staticClass: "tit font-tertiary font-weight-regular mb-14px mb-lg-0"
  }, [_vm._v(" 빌레로이앤보흐와 함께 집을 디자인 해보세요. ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "9"
    }
  }, [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "justify-lg": "end"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "main-design-card",
      attrs: {
        "flat": "",
        "max-width": "266",
        "rounded": "xs",
        "to": item.link
      }
    }, [_c('v-responsive', {
      attrs: {
        "aspect-ratio": 1 / 1
      }
    }, [_c('v-img', {
      attrs: {
        "eager": "",
        "src": item.image
      }
    }), _c('div', {
      staticClass: "main-design-card__name tit tit--sm font-tertiary white--text"
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }