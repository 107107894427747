export const BOARD_CATEGORIES__JFL_GUIDES = {
    FIRST_TIMERS: { value: "FIRST_TIMERS", text: "첫 구매 가이드" },
    MUST_NOTICES: { value: "MUST_NOTICES", text: "필독 가이드" },
    INSTALLATION: { value: "INSTALLATION", text: "설치 서비스" },
};

export const BOARD_CATEGORIES__COMMUNITY = {
    ["news"]: { value: "news", text: "새로운 소식" },
    ["know"]: { value: "know", text: "노하우" },
    ["self"]: { value: "self", text: "셀프가이드" },
    ["snse"]: { value: "snse", text: "SNS 이벤트" },
};
