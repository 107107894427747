export const FORM_CATEGORIES__JFL_CARES = {
    BUSINESS_TRIPS: { value: "BUSINESS_TRIPS", text: "출장 컨설팅" },
    ONLINE_CONSULT: { value: "ONLINE_CONSULT", text: "온라인 컨설팅" },
    DELIVERY_QUERY: { value: "DELIVERY_QUERY", text: "딜리버리 설치 서비스" },
    AFTER_SERVICES: { value: "AFTER_SERVICES", text: "전담 수리 서비스" },
    PRIVATES_QUERY: { value: "PRIVATES_QUERY", text: "안심 상담 서비스" },
};

export const FORM_CODES = {
    ["bulk-purchase"]: { value: "bulk-purchase", text: "대량구매요청" },
    ["concierge-service"]: { value: "concierge-service", text: "컨시어지 서비스" },
    ["inquiry-partner"]: { value: "inquiry-partner", text: "제휴문의" },
    ["user-consulting"]: { value: "user-consulting", text: "JFL 케어" },
    ["user-guide"]: { value: "user-guide", text: "JFL 가이드" },
    ["user-service"]: { value: "user-service", text: "회원 서비스" },
};
