var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.popup && _vm.show ? _c('div', {
    staticClass: "popup-layer",
    style: _vm.popupStyle
  }, [_c('div', {
    staticClass: "popup-layer__inner"
  }, [_c('v-card', {
    staticClass: "popup-layer__body",
    attrs: {
      "href": _vm.popup.url,
      "target": "_blank"
    }
  }, [_c('div', {
    staticClass: "popup-layer__content",
    domProps: {
      "innerHTML": _vm._s(_vm.popup.content.replace(/\n/g, '<br>'))
    }
  })]), _c('div', {
    staticClass: "popup-layer__foot"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "white--text",
    attrs: {
      "value": true,
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('span', {
          staticClass: "white--text"
        }, [_vm._v("오늘 하루 열지 않음")])];
      },
      proxy: true
    }], null, false, 2577427234),
    model: {
      value: _vm.doNotOpenToday,
      callback: function ($$v) {
        _vm.doNotOpenToday = $$v;
      },
      expression: "doNotOpenToday"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--text-fit font-weight-regular",
    attrs: {
      "text": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("닫기")])])], 1)], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }