/** @type {import("vuex").Module} */
export const enlipleTracker = {
    namespaced: true,
    actions: {
        callMtm(store, /** @type {EnlipleTrackerCallMtmProps|undefined} */ props) {
            window.ENP_VAR = {};
            if (window.CallMtm) CallMtm();
        },
        callMtmOnProductView(store, /** @type {EnlipleTrackerCallMtmProps & EnlipleTrackerProductViewProps} */ props) {
            let { click, convType, productName, ...collect } = props;

            collect.productName = productName;
            window.ENP_VAR = { collect };

            if (window.CallMtm) {
                let props = { productName, convType, click };
                if (!props.productName) delete props.productName;
                if (!props.click) delete props.click;

                CallMtm();
            }
        },
        callMtmOnCartPage(store, /** @type {EnlipleTrackerCallMtmProps & EnlipleTrackerCartPageProps} */ props) {
            let { basket, ...options } = props;

            window.ENP_VAR = { basket };

            if (window.CallMtm) {
                let props = options;
                if (!props.productName) delete props.productName;
                if (!props.click) delete props.click;

                CallMtm();
            }
        },
        callMtmOnOrderResult(store, /** @type {EnlipleTrackerCallMtmProps & EnlipleTrackerOrderResultProps} */ props) {
            let { conversion, ...options } = props;

            window.ENP_VAR = { conversion };

            if (window.CallMtm) {
                let props = options;
                if (!props.productName) delete props.productName;
                if (!props.click) delete props.click;

                CallMtm();
            }
        },
    },
};

/**
 * @typedef EnlipleTrackerCallMtmProps
 * @property {string} [productName] 광고주 측에서 설정하고 싶은 값 (default convType)
 * @property {'etc'|'join'|'login'} convType 필수 [etc, join, login]
 * @property {string} [click] click으로 전환 잡을 경우 css selector 값
 */

/**
 * @typedef EnlipleTrackerProductViewProps
 * @property {string} productCode 제품 코드
 * @property {string} productName 제품명
 * @property {string} price 제품가격
 * @property {string} dcPrice 제품 할인가격
 * @property {'Y'|'N'} soldOut 품절 여부 [Y: 품절제품, N: 판매제품]
 * @property {string} imageUrl 상품 이미지 URL
 * @property {string} secondImageUrl 상품 이미지 URL(다중이미지 사용시 세팅)
 * @property {string} thirdImageUrl 상품 이미지 URL(다중이미지 사용시 세팅)
 * @property {string} fourthImageUrl 상품 이미지 URL(다중이미지 사용시 세팅)
 * @property {string} topCategory 상품이 속한 카테고리의 최상위 분류
 * @property {string} firstSubCategory 대분류
 * @property {string} secondSubCategory 중분류
 * @property {string} thirdSubCategory 소분류
 */

/**
 * @typedef EnlipleTrackerCartPageProps
 * @property {object} basket
 * @property {EnlipleTrackerProductItem[]} basket.product 주문 상품
 * @property {string} basket.totalPrice 총 주문 금액
 * @property {string} basket.totalQty 총 주문 수량
 */

/**
 * @typedef EnlipleTrackerOrderResultProps
 * @property {object} conversion
 * @property {EnlipleTrackerProductItem[]} conversion.product 주문 상품
 * @property {string} conversion.ordCode 주문 번호
 * @property {string} conversion.totalPrice 총 주문 금액
 * @property {string} conversion.totalQty 총 주문 수량
 */

/**
 * @typedef EnlipleTrackerProductItem
 * @property {string} productCode 제품 코드
 * @property {string} productName 제품명
 * @property {string} price 제품 할인 가격
 * @property {string} qty 제품 수량
 */
