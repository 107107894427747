var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navigation-tab-item-wrap"
  }, [_c('div', {
    staticClass: "txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px"
  }, [_vm._v("Collection")]), _c('v-tabs', {
    staticClass: "navigation-tabs navigation-tabs--2",
    attrs: {
      "vertical": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-tab', {
      key: item.text,
      staticClass: "v-tab--inactive",
      attrs: {
        "to": item.value
      }
    }, [_c('span', [_vm._v(" " + _vm._s(item.text) + " ")])])];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }