export const SHIPPING_CODES = {
    EXPRESS_COURIER_DELIVERY: { value: "EXPRESS_COURIER_DELIVERY", text: "퀵업체" },
    GENERAL_COURIER_DELIVERY: { value: "GENERAL_COURIER_DELIVERY", text: "택배업체" },
    FREIGHT_COURIER_DELIVERY: { value: "FREIGHT_COURIER_DELIVERY", text: "화물업체" },
    COMPANY_DELIVERY_SERVICE: { value: "COMPANY_DELIVERY_SERVICE", text: "자체배송" },
    COMPANY_EXTENDED_SERVICE: { value: "COMPANY_EXTENDED_SERVICE", text: "특별배송" },
};

export const SHIPPING_TARGET_TYPES = {
    EVERY_ASSET: { value: "EVERY_ASSET", text: "전체" },
    BY_PRODUCTS: { value: "BY_PRODUCTS", text: "상품별" },
    BY_CATBRAND: { value: "BY_CATBRAND", text: "카테고리별 + 브랜드별" },
    BY_CATEGORY: { value: "BY_CATEGORY", text: "카테고리별" },
    BY_BRANDING: { value: "BY_BRANDING", text: "브랜드별" },
};

export const SHIPPING_OPTION_BUNDLE_TYPES = {
    BUNDLE: { value: "BUNDLE", text: "묶음배송" },
    APIECE: { value: "APIECE", text: "개별배송" },
};

export const SHIPPING_OPTION_CHARGE_TYPES = {
    PAY_ON_DELIVERY: { value: "PAY_ON_DELIVERY", text: "착불" },
    BY_FIXED_AMOUNT: { value: "BY_FIXED_AMOUNT", text: "고정" },
    BY_PRICES_RANGE: { value: "BY_PRICES_RANGE", text: "구간별" },
    BY_TRAVEL_RANGE: { value: "BY_TRAVEL_RANGE", text: "거리별" },
};
