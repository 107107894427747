import Vue from "vue";
import XLSX from "xlsx";

function excel(items, headers, fileName) {
    const rows = items.map((item, index) => {
        item = { ...item, index: item.index ?? items.length - index };

        return headers.reduce((row, header) => {
            if (header.hideOnExcel) return row;

            const name = header.text;
            if (!name) return row;

            const formatter = header?.excel ?? header?.formatter;
            const keys = header.value.split(".");

            let data = keys.reduce((data, key) => data?.[key], item);
            data = formatter.bind(item)(data, item, true);

            if (header.splitOnExcel) data = data.reduce((object, item, index) => ({ ...object, [header.text + (index + 1)]: item }), {});
            else data = { [header.text]: data };

            return { ...row, ...data };
        }, {});
    });

    const workbook = new XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, `${fileName}-${this.$dayjs().format("YYYYMMDD_HHmmss")}.xlsx`);
}

/**
 * @param {Vue} Vue
 * @param {*} options
 */
const install = function (Vue, options) {
    Vue.prototype.$excel = excel;
};

Vue.use({ install });

export default excel;
