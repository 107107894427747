export const PRODUCT_TYPES = {
    NORMAL_PRODUCT: { value: "NORMAL_PRODUCT", text: "일반상품" },
    VARIETY_BUNDLE: { value: "VARIETY_BUNDLE", text: "묶음상품" },
    PRODUCT_BUNDLE: { value: "PRODUCT_BUNDLE", text: "세트상품" },
    TARGET_PAYMENT: { value: "TARGET_PAYMENT", text: "개인결제" },
};

export const PRODUCT_OPTION_SORTS = {
    LATEST: { value: "LATEST", text: "등록일자순" },
    NAME: { value: "NAME", text: "이름순" },
    PRICE_ASC: { value: "PRICE_ASC", text: "낮은가격순" },
    PRICE_DESC: { value: "PRICE_DESC", text: "높은가격순" },
};

export const PRODUCT_OPTION_CRITERIA_TYPES = {
    COLOR: { value: "COLOR", text: "색상" },
    OTHER: { value: "OTHER", text: "일반" },
};
