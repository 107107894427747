import axios from "@/plugins/axios";

let url = "/api/v1/me/inquires";

export default {
    gets(data) {
        return axios.get(url, data).then((result) => result.data);
    },
    post(data) {
        return axios.post(url, data).then((result) => result.data);
    },
    put(data) {
        return axios.put(`${url}/${data._id}`, data).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`, data).then((result) => result.data);
    },
    images: {
        post({ _id, index }, file) {
            var headers = { "Content-Type": "multipart/formdata" };

            var formData = new FormData();
            formData.append("image", file);

            return axios.post(`${url}/${_id}/images/${index}`, formData, { headers }).then((result) => result.data);
        },
    },
};
