const navigation = [
    {
        text: "제품",
        children: [
            {
                text: "욕실",
                children: [
                    {
                        text: "욕실 개요",
                        value: { path: "/ideas/bathroom/overview" },
                    },
                    {
                        text: "욕실 플래너",
                        value: { path: "/ideas/bathroom/planner" },
                    },
                    {
                        text: "욕실 아이디어 및 트렌드",
                        value: { path: "/ideas/bathroom/trends" },
                    },
                    {
                        text: "욕실 바꾸기",
                        value: { path: "/ideas/bathroom/renovation" },
                    },
                ],
            },
        ],
    },
    {
        text: "아이디어 및 플래너",
        children: [
            {
                text: "욕실",
                children: [
                    {
                        text: "욕실 개요",
                        value: { path: "/ideas/bathroom/overview" },
                    },
                    {
                        text: "욕실 플래너",
                        value: { path: "/ideas/bathroom/planner" },
                    },
                    {
                        text: "욕실 아이디어 및 트렌드",
                        value: { path: "/ideas/bathroom/trends" },
                    },
                    {
                        text: "욕실 바꾸기",
                        value: { path: "/ideas/bathroom/renovation" },
                    },
                ],
            },
            {
                text: "주방",
                children: [
                    {
                        text: "주방에 대한 영감",
                        value: { path: "/ideas/kitchen/overview" },
                    },
                    {
                        text: "주방 관리 팁",
                        value: { path: "/ideas/kitchen/cleaning" },
                    },
                    {
                        text: "주방 소재",
                        value: { path: "/ideas/kitchen/material" },
                    },
                ],
            },
        ],
    },
    {
        text: "쇼핑",
        value: { path: "/shop/sales" },
    },
    {
        text: "회사",
        children: [
            {
                text: "빌레로이앤보흐",
                children: [
                    {
                        text: "성과",
                        value: { path: "/company/about/figures" },
                    },
                    {
                        text: "비전과 미션",
                        value: { path: "/company/about/vision" },
                    },
                ]
            },
            {
                text: "품질과 혁신",
                children: [
                    {
                        text: "창의적 제품",
                        value: { path: "/company/innovation/products" },
                    },
                    {
                        text: "수상경력",
                        value: { path: "/company/innovation/awards" },
                    },
                    {
                        text: "Digital Services",
                        value: { path: "/company/innovation/digital" },
                    },
                ]
            },
            {
                text: "역사와 전통",
                children: [
                    {
                        text: "역사",
                        value: { path: "/company/history/history" },
                    },
                    {
                        text: "전통",
                        value: { path: "/company/history/tradition" },
                    },
                ]
            },
            {
                text: "관련 브랜드",
                value: { path: "/company/brands" },
            },
            {
                text: "사회적 책임",
                value: { path: "/company/responsibility" },
            },
        ],
    },
];

export default navigation;
