/** @type {import("vuex").Module} */
export const naverWcs = {
    namespaced: true,
    actions: {
        wcs_do_cnv(store, { type = "1", amount = 0 } = {}) {
            var _nasa = {};
            let intervalId = setInterval(() => {
                if (window.wcs) {
                    clearInterval(intervalId);
                    _nasa["cnv"] = wcs.cnv(type, amount.toString());
                    store.dispatch("wcs_do", _nasa);
                }
            }, 500);
        },
        wcs_do(store, _nasa = {}) {
            if (window.wcs) {
                wcs.inflow("joeyforlfie.com");
                wcs_do(_nasa);
            }
        },
    },
};
