var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-section",
    class: _vm.bg ? 'page-section--bg' : ''
  }, [this.$slots['containerImmersive'] ? _vm._t("containerImmersive") : [_c('tit-wrap-subtitle', _vm._b({
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'tit-wrap-subtitle', _vm.$attrs, false)), _vm._t("default")], _vm.bg ? [_c('v-sheet', {
    staticClass: "page-section__bg",
    class: _vm.bg === 'left' ? 'page-section__bg--left' : 'page-section__bg--right'
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }