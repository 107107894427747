<template id="modal-template">
    <u-dialog v-model="shows" name="modal" v-bind="{ title }">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <tit-wrap-x-small title="문의 제목"></tit-wrap-x-small>
        <v-text-field v-model="form.subject" dense outlined hide-details placeholder="문의 제목을 입력해주세요." />

        <page-section class="page-section--xs pb-0">
            <tit-wrap-x-small title="문의 내용"></tit-wrap-x-small>
            <v-textarea v-model="form.content" dense outlined hide-details placeholder="문의 내용을 입력해주세요." />
            <div class="pt-8px">
                <v-checkbox v-model="form.secret" v-bind="{ ...checkbox_primary }" :value="true" hide-details label="비공개" />
            </div>
        </page-section>

        <page-section class="page-section--xs pb-0">
            <tit-wrap-x-small title="이미지 등록"></tit-wrap-x-small>
            <form-files v-model="form.images" />
        </page-section>

        <template #actions>
            <v-row justify="center" class="row--sm">
                <v-col cols="12" md="auto">
                    <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-200px" @click="submit">{{ title }}</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { btn_primary, checkbox_primary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import FormFiles from "@/components/client/dumb/form-files.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

const initForm = (form = {}) => ({
    ...(form || {}),
    subject: form?.subject ?? null,
    content: form?.content ?? null,
    secret: form?.secret ?? false,
    images: [...(form.images || [])],
});

export default {
    props: {
        value: { type: Object, default: () => ({}) },
        product: { type: Object, default: null },
    },
    components: {
        PageSection,
        UDialog,
        FormFiles,
        TitWrapXSmall,
    },
    data: () => ({
        btn_primary,
        checkbox_primary,

        shows: false,

        form: initForm(),
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "문의하기" : "수정하기";
        },
    },
    methods: {
        async sync() {
            this.form = initForm(this.value);
        },
        async init() {
            if (this.isCreate) return;
            this.form.images = await Promise.all(this.form.images.map(async (fileName) => await api.getResource(`/res/shop/inquires/${this.value?._id}/${fileName}`, true)));
        },
        async submit() {
            try {
                if (!this.accessToken) throw new Error("로그인 이후 이용가능합니다");

                let { images, ...form } = this.form;
                if (this.isCreate) form._product = this.product._id;

                const { post, put } = api.v1.me.inquires;
                let { inquire } = await (this.isCreate ? post : put)(form);

                images = await Promise.all(images.map(async (image, index) => (await api.v1.me.inquires.images.post({ _id: inquire._id, index }, image))?.image));

                await put({ ...inquire, images });

                if (this.isCreate) alert("상품문의가 등록되었습니다");
                else alert("상품문의가 수정되었습니다");

                this.$emit("search");
            } finally {
                this.shows = false;
            }
        },
    },
    watch: {
        value() {
            this.sync().then(this.init);
        },
    },
    mounted() {
        this.sync().then(this.init);
    },
};
</script>
