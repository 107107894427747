import axios from "@/plugins/axios";

let url = "/api/console/shop/orders";

export default {
    // getOrders(data){
    //     return axios.get(url, data).then(result => result.data);
    // },
    getOrder(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    // postOrder(data){
    //     return axios.post(url, data).then(result => result.data);
    // },
    putOrder(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    // deleteOrder(data){
    //     return axios.delete(`${url}/${data._id}`).then(result => result.data);
    // }
}
