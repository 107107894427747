export const CENTER_QUESTION_CODES = {
    QUESTIONS: { value: "QUESTIONS", text: "1:1문의" },
    INQUIRIES: { value: "INQUIRIES", text: "문의하기" },
};

export const CENTER_QUESTION_TYPES = {
    [CENTER_QUESTION_CODES.QUESTIONS.value]: {
        ACCOUNTS: { value: "ACCOUNTS", text: "회원정보 문의" },
        PURCHASE: { value: "PURCHASE", text: "주문/결제 문의" },
        PRODUCTS: { value: "PRODUCTS", text: "상품 문의" },
        DELIVERY: { value: "DELIVERY", text: "배송 문의" },
        COMPLAIN: { value: "COMPLAIN", text: "교환/반품 문의" },
        BENEFITS: { value: "BENEFITS", text: "이벤트/혜택 문의" },
        SERVICES: { value: "SERVICES", text: "서비스 개선 의견" },
        ETCETERA: { value: "ETCETERA", text: "기타" },
    },
    [CENTER_QUESTION_CODES.INQUIRIES.value]: {
        PRODUCTS: { value: "PRODUCTS", text: "제품관련문의" },
        SERVICES: { value: "SERVICES", text: "배송·설치 서비스" },
        DIAGNOSE: { value: "DIAGNOSE", text: "욕실·주방 진단" },
        RENOVATE: { value: "RENOVATE", text: "리모델링 컨설팅" },
        ETCETERA: { value: "ETCETERA", text: "기타" },
    },
};
