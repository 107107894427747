/** @type {import("vuex").Module} */
export const metaPixel = {
    namespaced: true,
    actions: {
        fbq(store, { type, options } = {}) {
            if (window.fbq) {
                if (!options) fbq("track", type);
                else fbq("track", type, options);
            }
        },
    },
};
