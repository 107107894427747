import { initGeolocation } from "./common.inits";

export const initDestination = (destination = {}) => ({
    ...destination,
    title: destination?.title ?? null,

    name: destination?.name ?? null,
    phone: destination?.phone ?? null,
    email: destination?.email ?? null,
    postcode: destination?.postcode ?? null,
    address1: destination?.address1 ?? null,
    address2: destination?.address2 ?? null,

    geolocation: initGeolocation(destination?.geolocation),

    isDefault: destination?.isDefault || false,
});
