<template>
    <div class="navigation-tab-item-wrap">
        <div class="txt txt--dark font-secondary font-weight-medium mb-12px mb-md-16px">Collection</div>
        <v-tabs vertical class="navigation-tabs navigation-tabs--2">
            <template v-for="item in items">
                <v-tab :key="item.text" class="v-tab--inactive" :to="item.value">
                    <span>
                        {{ item.text }}
                    </span>
                </v-tab>
            </template>
        </v-tabs>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                text: "Bathroom Collection : 욕실 컬렉션",
                value: { path: "/" },
            },
            {
                text: "Kitchen Collection : 주방 컬렉션",
                value: { path: "/" },
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.navigation-tabs {
    .v-tab {
        width: fit-content;
        height: auto;
        padding: 0;
        text-transform: initial;
        color: var(--v-grey-darken3) !important;
    }
    &--2 {
        margin: -8px 0;
        .v-tab {
            font-size: var(--txt-font-size-xs) !important;
            font-weight: 300;
            margin: 8px 0;
            padding-left: 8px;
            &::before {
                display: none;
            }
            &:hover {
                color: #196bdc !important;
            }
        }
    }
}
</style>