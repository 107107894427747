/** @type {import("vuex").Module} */
export const bandBanners = {
    namespaced: true,
    state: {
        items: [],
        disableds: [],
    },
    mutations: {
        setItems(state, { bandBanners }) {
            console.log(state)
            state.items = bandBanners;
        },
        hideItem(state, { _id }) {
            console.log("hidItem");
            state.disableds.push(_id);
        },
        setDisabled(state, { _id }) {
            console.log(_id)
            state.disableds.push(_id);
        }
    },
};
