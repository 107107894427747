<template>
    <div class="footer footer-navigation">
        <div class="footer-top-button">
            <v-btn icon large elevation="4" color="white" @click="scrollToTop">
                <v-img max-width="24" src="/images/icon/icon-arrow-up.svg"></v-img>
            </v-btn>
        </div>
        <v-container class="container--lg">
            <v-row>
                <!-- 제품페이지 네비게이션 -->
                <template v-for="category in categories">
                    <v-col cols="12" md="3" :key="category.code">
                        <div class="txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px">
                            {{ category.name }}
                        </div>
                        <v-row class="row--sm">
                            <template v-for="child in category.children">
                                <v-col cols="12" :key="child.code">
                                    <a :href="`/shop?category=${child.code}`" class="txt txt--sm txt--dark font-weight-light">
                                        {{ child.name }}
                                    </a>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </template>

                <!-- 서브페이지 네비게이션 -->
                <template v-for="item in items">
                    <v-col cols="12" md="3" :key="item.text">
                        <div class="txt txt--sm txt--dark font-weight-medium font-secondary pb-18px pb-md-24px">
                            {{ item.text }}
                        </div>
                        <v-row class="row--sm">
                            <template v-for="child in item.children">
                                <v-col cols="12" :key="child.text">
                                    <router-link :to="child.value" class="txt txt--sm txt--dark font-weight-light">
                                        {{ child.text }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState } from "vuex";
import navigation from "@/store/ui/index.js";

export default {
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
    data: () => ({
        items: [
            {
                text: "Room ideas",
                children: [
                    {
                        text: "욕실 개요",
                        value: { path: "/ideas/bathroom/overview" },
                    },
                    {
                        text: "욕실 플래너",
                        value: { path: "/ideas/bathroom/planner" },
                    },
                    {
                        text: "욕실 아이디어 및 트렌드",
                        value: { path: "/ideas/bathroom/trends" },
                    },
                    {
                        text: "욕실 바꾸기",
                        value: { path: "/ideas/bathroom/renovation" },
                    },
                    {
                        text: "주방에 대한 영감",
                        value: { path: "/ideas/kitchen/overview" },
                    },
                    {
                        text: "주방 관리 팁",
                        value: { path: "/ideas/kitchen/cleaning" },
                    },
                    {
                        text: "주방 소재",
                        value: { path: "/ideas/kitchen/material" },
                    },
                ],
            },
            {
                text: "Service",
                children: [
                    {
                        text: "개인정보 처리방침",
                        value: { path: "/privacy-policy" },
                    },
                    {
                        text: "이용약관",
                        value: { path: "/terms" },
                    },
                ]
            },
        ],
    }),
    computed: {
        ...mapState(["categories"]),
    },
    // watch: {
    //     categories() {
    //         this.init();
    //     },
    // },
    // mounted() {
    //     this.init();
    // },
};
</script>

<style lang="scss" scoped>
.footer-navigation {
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.footer-top-button {
    position: absolute;
    top: -22px;
    right: 10%;
    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #fff;
    }
    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 768px) {
    .footer-navigation {
        padding-top: 68px;
    }
}
</style>