import { render, staticRenderFns } from "./main-about.vue?vue&type=template&id=0980fd08&scoped=true"
import script from "./main-about.vue?vue&type=script&lang=js"
export * from "./main-about.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0980fd08",
  null
  
)

export default component.exports