<template>
    <main-section>
        <v-container>
            <v-row class="row--lg">
                <v-col cols="12" md="3">
                    <div class="section-title">
                        <div class="txt txt--xs font-secondary spaced-text mb-12px mb-md-16px">쇼핑</div>
                        <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">세면기</h2>
                        <p class="txt txt--sm font-secondary">상품 둘러보기</p>
                        <div class="mt-18px mt-md-24px">
                            <v-row class="row--xs">
                                <v-col cols="auto">
                                    <v-btn x-large icon outlined class="swiper-button swiper-button--prev">
                                        <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-large icon outlined class="swiper-button swiper-button--next">
                                        <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" md="9">
                    <swiper class="swiper" v-bind="{ options }">
                        <template v-for="(product, index) in products">
                            <swiper-slide :key="index">
                                <shop-product-card v-bind="{ value: product }" />
                            </swiper-slide>
                        </template>
                    </swiper>

                    <v-sheet color="grey lighten-5 mt-18px mt-md-24px pa-14px px-md-24px py-md-16px">
                        <v-row justify="space-between" align="center">
                            <v-col cols="12" md="auto">
                                <div class="txt txt--sm txt--dark">세면기에 대해 더 알아보세요</div>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <v-btn large color="white" class="rounded-xs sales-button" :to="`/shop/products?category=${category?.code}`">
                                    더보기
                                    <v-img src="/images/icon/icon-favorite-plus.svg" class="ml-8px" />
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </main-section>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainSection from "@/components/client/templates/main-section.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";
import { DISPLAY_CODES } from "@/assets/variables";

export default {
    components: {
        Swiper,
        SwiperSlide,
        MainSection,
        ShopProductCard,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            slidesPerView: 2,
            spaceBetween: 32,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            },
        },
        category: {},
        products: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.searchCategory();
            await this.search();
        },
        async searchCategory() {
            try {
                let { categories } = await api.v1.shop.categories.gets({
                    params: { code: "bathroom_sink" },
                });

                this.category = categories[0];
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            try {
                let { products } = await api.v1.shop.products.gets({
                    headers: { limit: 12 },
                    params: { _category: this.category._id, groups: { $in: [DISPLAY_CODES.MAIN_SHOWS.value] } },
                });

                this.products = products;
            } catch (error) {
                this.$handleError(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    overflow: visible;
}
.swiper-container {
    overflow: visible;
}
.spaced-text {
    letter-spacing: 2px;
}
.section-title {
    position: relative;
    z-index: 2;
    background-color: #fff;
    height: 100%;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100vw;
        width: 100vw;
        height: 100%;
        background-color: inherit;
    }
}
.sales-button {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
    .v-btn.swiper-button {
        width: 64px !important;
        height: 64px !important;
    }
}
</style>